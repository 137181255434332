import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { removeItem, replaceQuantity } from "../cartSlice";

export default function CartItem({ item }) {
  var options = [];

  const dispatch = useDispatch();

  function handleClick(event) {
    dispatch(replaceQuantity(event));
  }


  const [startCantitate, setStartCantitate] = useState(5);
  const [endCantitate, setEndCantitate] = useState(30);

  useEffect(() => {
    if (item.ziLivrare.stoc > 30) {
      setEndCantitate(30);
    } else {
      setEndCantitate(item.ziLivrare.stoc);
    }

    if (
      item.attributes.Slug === "matci-carnica-breeder" ||
      item.attributes.Slug === "matci-carnica-iernate"
    ) {
      setStartCantitate(1);
      setEndCantitate(25);
    }
  }, []);

  const cart = useSelector((state) => state.persistedReducer.cart);
  for (var i = startCantitate; i <= endCantitate; i++) {
    options.push(
      <option value={i} selected={i === item.quantity ? true : false}>
        {i}
      </option>
    );
  }

  return (
    <>
      <div className="cart__item">
        <Link to={`/produse/${item.attributes.Slug}`}>
          <div>
            <img
              src={`https://api.matcicarnica.ro/${item.attributes.Poze.data[0].attributes.formats.small.url}`}
              alt=""
              className="!w-full lg:!w-[250px]"
            />
          </div>
        </Link>
        <div className="cart__item__descriere ">
          <span className="titlu">{item.attributes.Titlu}</span>
          <span className="text-md !mb-[30px] sm:!m-0">
            Livrare : {item.livrare}
          </span>
          <div className="d-flex align-items-center">
            <select
              name="numar"
              id="numar"
              onChange={(e) =>
                dispatch(
                  replaceQuantity([Number(e.target.value), item.livrare])
                )
              }
            >
              {options.map((item) => {
                return item;
              })}
            </select>
            <span>
              x {item.attributes.Pret} Lei (
              {item.quantity * item.attributes.Pret} Lei)
            </span>
            <button
              className="border-0 w-[30px] h-[30px] !border !border-slate-300"
              onClick={() => dispatch(removeItem(item.livrare))}
            >
              ✕
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
