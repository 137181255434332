import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addLivrare, addToCart } from "../cartSlice";
import { set, useForm } from "react-hook-form";
import { toast } from "react-toastify";

const qs = require("qs");

const query = (filtru) =>
  qs.stringify(
    {
      filters: {
        Slug: {
          $eq: filtru,
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

export default function ProdusPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const notify = () =>
    toast.success(
      <Link to="/cart">
        <div>
          Produs adaugat cu succes!
          <br />
          Fa click ca sa mergi catre cos
        </div>
      </Link>,
      {
        position: "bottom-center",
        autoClose: 4000,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
      }
    );
  useEffect(() => {
    window.scroll({ top: 0, behavior: "instant" });
  }, []);

  const { id } = useParams();

  const dispatch = useDispatch();

  //produsul in sine
  const [produs, setProdus] = useState({});
  //obiect recieved din API
  const [livrare, setLivrare] = useState();
  //obiect stiliazt pentru frontend
  const [zileLivrare, setZileLivrare] = useState();
  //obiect pentru ziua de backend
  const [ziLivrare, setZiLivrare] = useState();
  const [ziLivrareFrontend, setZiLivrareFrontend] = useState();
  //sa arat buton pentru produs?
  const [buton, setButon] = useState(true);
  const [indisponibil, setIndisponibil] = useState(false);
  //cantitatea produsului de pe frontend
  const [cantitate, setCantitate] = useState(1);
  //inceput cantitate
  const [endCantitate, setEndCantitate] = useState();
  //limita cantitate
  const [startCantitate, setStartCantitate] = useState(1);

  //optiunile de la selectu de cantitate
  const [options, setOptions] = useState([]);
  //useStateu pentru data livrarii

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(
          `https://api.matcicarnica.ro/api/produse?populate=*&${query(id)}`
        )
        .then((data) => {
          setProdus(data.data.data);
          setLivrare(data.data.data[0].attributes.saptamana);
        })
        .catch(console.error);
    };
    fetchData();
  }, [id]);

  const onSubmit = (data) => {
    notify();
    dispatch(
      addToCart([
        Number(data.numar),
        produs[0].id,
        produs[0],
        data.livrare,
        ziLivrare,
      ])
    );
    if (cantitate * 2 > endCantitate) setCantitate(startCantitate);
  };

  const cart = useSelector((state) => state.persistedReducer.cart);

  //obiect pentru zilele neprocesate pentru backend si zilele procesate / prettified pentru frontend
  let zi = new Map();
  function getDay(caz) {
    if (caz === "matci-carnica-fecundate") {
      setStartCantitate(5);
      livrare.forEach((item) => {
        const date = new Date(item.data);
        zi.set(
          item,
          <option id={item.id}>{`Miercuri, ${date
            .getDate()
            .toString()} ${map.get(date.getMonth())}`}</option>
        );
      });
      setZileLivrare(zi);
      // while (refDate.getFullYear() <= 2023) {
      //   zi.push(
      //     <option>{`Miercuri, ${refDate.getDate().toString()} ${map.get(
      //       refDate.getMonth()
      //     )}`}</option>
      //   );
      //   refDate.setDate(refDate.getDate() + 7);
      //}
    } else if (caz === "matci-carnica-nefecundate") {
      setStartCantitate(5);
      livrare.forEach((item) => {
        const date = new Date(item.data);
        zi.set(
          item,
          date.getDay() === 3 ? (
            <option id={item.id}>{`Miercuri, ${date
              .getDate()
              .toString()} ${map.get(date.getMonth())}`}</option>
          ) : (
            <option id={item.id}>{`Joi, ${date.getDate().toString()} ${map.get(
              date.getMonth()
            )}`}</option>
          )
        );
      });
      setZileLivrare(zi);
      // while (refDate.getFullYear() <= 2023) {
      //   zi.push(
      //     <option>{`Miercuri, ${refDate.getDate()} ${map.get(
      //       refDate.getMonth()
      //     )}`}</option>
      //   );
      //   refDate.setDate(refDate.getDate() + 1);
      //   zi.push(
      //     <option>{`Joi, ${refDate.getDate()} ${map.get(
      //       refDate.getMonth()
      //     )}`}</option>
      //   );
      //   refDate.setDate(refDate.getDate() + 6);
      // }
    } else if (caz === "botci-carnica") {
      setButon(false);
      setStartCantitate(5);

      // while (refDate.getFullYear() <= 2023) {
      //   if (refDate.getDay() === 5) {
      //     zi.push(
      //       <option>{`Vineri, ${refDate.getDate()} ${map.get(
      //         refDate.getMonth()
      //       )}`}</option>
      //     );
      //     refDate.setDate(refDate.getDate() + 3);
      //   } else {
      //     zi.push(
      //       <option>{`Luni, ${refDate.getDate()} ${map.get(
      //         refDate.getMonth()
      //       )}`}</option>
      //     );
      //     refDate.setDate(refDate.getDate() + 4);
      //   }
      // }
    } else if (caz === "matci-carnica-iernate") {
      setCantitate(1);
      setStartCantitate(1);
      setStartCantitate(1);
      livrare.forEach((item) => {
        const date = new Date(item.data);
        zi.set(
          item,
          <option id={item.id}>{`${date.getDate().toString()} ${map.get(
            date.getMonth()
          )}`}</option>
        );
      });
      setZileLivrare(zi);
      // zi.push(
      //   <option>{`${refDate.getDate()} ${map.get(refDate.getMonth())}`}</option>
      // );
    } else if (caz === "matci-carnica-breeder") {
      setCantitate(1);
      setStartCantitate(1);
      setEndCantitate(25);
      livrare.forEach((item) => {
        const date = new Date(item.data);
        zi.set(
          item,
          <option id={item.id}>{`Miercuri ${date
            .getDate()
            .toString()} ${map.get(date.getMonth())}`}</option>
        );
      });
      setZileLivrare(zi);
      //   while (refDate.getFullYear() <= 2023) {
      //     zi.push(
      //       <option>{`Miercuri, ${refDate.getDate()} ${map.get(
      //         refDate.getMonth()
      //       )}`}</option>
      //     );
      //     refDate.setDate(refDate.getDate() + 7);
      //   }
    }
  }

  const luni = [
    "Ianuarie",
    "Februarie",
    "Martie",
    "Aprilie",
    "Mai",
    "Iunie",
    "Iulie",
    "August",
    "Septembrie",
    "Octombrie",
    "Noiembrie",
    "Decembrie",
  ];
  const map = new Map();
  for (var i = 0; i < 12; i++) {
    map.set(i, luni.at(i));
  }

  function configurareZileLivrari(startStoc, limitaStoc, itemInCart, items) {
    if (ziLivrare.stoc > limitaStoc) {
      setIndisponibil(false);
      if (itemInCart) {
        setEndCantitate(limitaStoc - itemInCart.quantity);
      } else setEndCantitate(limitaStoc);
      for (var i = startCantitate; i <= endCantitate; i++) {
        items.push(<option value={i}>{i}</option>);
      }
      setOptions(items);
    } else if (ziLivrare.stoc > startStoc) {
      setIndisponibil(false);
      if (itemInCart) {
        setEndCantitate(ziLivrare.stoc - itemInCart.quantity);
      } else setEndCantitate(ziLivrare.stoc);
      for (var i = startCantitate; i <= endCantitate; i++) {
        items.push(<option value={i}>{i}</option>);
      }
      setOptions(items);
    } else {
      setIndisponibil(true);
    }
  }

  useEffect(() => {
    getDay(produs[0]?.attributes.Slug);
    setZileLivrare(zi);
  }, [livrare]);

  useEffect(() => {
    if (ziLivrare) {
      var items = [];
      const itemInCart = cart.find(
        (items) => items.livrare === ziLivrareFrontend
      );

      if (produs[0]?.attributes.Slug === "matci-carnica-fecundate"){
        configurareZileLivrari(5, 40, itemInCart, items);
      }
      else if (produs[0]?.attributes.Slug === "matci-carnica-nefecundate") {
        configurareZileLivrari(5, 30, itemInCart, items);
      } 
      else if (produs[0]?.attributes.Slug === "matci-carnica-breeder") {
        configurareZileLivrari(1, 25, itemInCart, items);
      }
      else if (produs[0]?.attributes.Slug === "matci-carnica-iernate") {
        configurareZileLivrari(1, 25, itemInCart, items);
      }
    }
  }, [
    ziLivrare,
    ziLivrareFrontend,
    endCantitate,
    startCantitate,
    cart,
    produs,
  ]);

  return (
    <div className="produs__div">
      <div className="produs__image">
        <img
          src={`https://api.matcicarnica.ro${produs[0]?.attributes.Poze.data[0].attributes.url}`}
          alt=""
          className="shadow border"
        />
      </div>
      <div className="produs__descriere">
        <h6>{produs[0]?.attributes.Titlu}</h6>
        <span className="text-2xl lg:text-3xl">
          {produs[0]?.attributes.Pret} Lei / buc
        </span>
        {buton ? (
          <>
            <p className="text-gray-400 whitespace-pre-line">
              * Selectați data de livrare, apoi cantitatea.<br></br>* Produsul
              poate fi indisponibil în anumite dăți.<br></br>* Cantitatea
              produsului poate fi modificată și în coș.
            </p>
            <form
              className="mt-5"
              id="produs-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-row items-center mt-5">
                {!indisponibil ? (
                  <button
                    form="produs-form"
                    className="m-0 px-[1.8rem] py-3 text-sm lg:text-xl font-bold text-[#482627] bg-[#FEA21D]"
                  >
                    ADAUGĂ ÎN COȘ
                  </button>
                ) : (
                  <button
                    form="produs-form"
                    disabled
                    className="m-0 px-[1.8rem] py-3 text-lg lg:text-xl font-bold text-[#482627] bg-[#FEA21D]"
                  >
                    INDISPONIBIL
                  </button>
                )}
                <select
                  className="select"
                  name="numar"
                  {...register("numar", {
                    validate: (value) => {
                      return value !== "DEFAULT";
                    },
                  })}
                  style={errors.numar && { border: "1px solid red" }}
                  id="numar"
                  defaultValue={"DEFAULT"}
                  onChange={(e) => {
                    setCantitate((prevCantitare) => e.target.value);
                  }}
                >
                  <option value="DEFAULT" disabled>
                    - Cantitate -
                  </option>
                  {options.map((item) => {
                    return item;
                  })}
                </select>
              </div>
              <div className="my-5 mt-[3rem]">
                <span className="block !text-left text-2xl mb-5">Data de livrare:</span>
                <select
                  className="border border-1"
                  name="livrare"
                  id="livrare"
                  {...register("livrare", {
                    validate: (value) => {
                      return value !== "DEFAULT";
                    },
                  })}
                  defaultValue={"DEFAULT"}
                  onChange={(e) => {
                    zileLivrare &&
                      setZiLivrare(
                        [...zileLivrare.keys()].find(
                          (item) =>
                            item.id ===
                            parseInt(e.target[e.target.selectedIndex].id)
                        )
                      );
                    setZiLivrareFrontend(e.target.value);
                  }}
                  style={errors.livrare && { border: "1px solid red" }}
                >
                  <option value="DEFAULT" disabled>
                    - Selecteaza data de livrare -
                  </option>
                  {zileLivrare &&
                    Array.from(zileLivrare.entries()).map((entry) => {
                      const [key, value] = entry;
                      return value;
                    })}
                </select>
                {errors.livrare && (
                  <p className="text-red-500 !my-2 !text-lg">
                    Va rugam selectati data de livrare
                  </p>
                )}
              </div>
            </form>
          </>
        ) : (
          <span className="!text-left mt-5 text-xl md:text-3xl">
            {" "}
            Botcile se ridică personal de la noi din stupină (Deva), comanda se
            poate face telefonic la numarul de telefon -{" "}
            <span className="whitespace-nowrap">0733 623 202</span>
          </span>
        )}
        <hr />
        <p>{produs[0]?.attributes.Descriere}</p>
      </div>
    </div>
  );
}
