import Card from "./Card";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

export default function BodySection1() {
  const [produse, setProduse] = useState([]);

  useEffect(() => {
    async function getData() {
      await axios
        .get(
          "https://api.matcicarnica.ro/api/produse?populate=*&sort=rank:asc"
        )
        .then((response) => setProduse(response.data.data))
        .catch((err) => console.log(err));
    }
    getData();
  }, []);

  const items = [
    produse.map((produs) => {
      return (
        <Card
          slug={produs.attributes.Slug}
          titlu={produs.attributes.Titlu}
          pret={produs.attributes.Pret}
          produs={produs}
          imagine={`https://api.matcicarnica.ro${produs.attributes.Poze.data[0].attributes.formats.medium.url}`}
        />
      );
    }),
  ];

  return (
    <div id="body__section__1">
      <h4 className="text-left after:mx-0 text-[#231F1E] ml-5 text-4xl lg:!text-5xl">
        Produsele noastre
      </h4>
      <div className="carousel">{items}</div>
    </div>
  );
}
