import logo from "./logo.webp";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { FaFacebookF } from "react-icons/fa";

export default function Footer() {
  const location = useLocation();

  return (
    <footer className="p-3 md:!p-4 lg:!p-[5rem] bg-[#E7E7E7]">
      <div
        className="text-white flex flex-col lg:flex-row border-solid border-3
        border-b-[1px] border-[lightgray] pb-[3rem] lg:!pb-3"
      >
        <div className="w-full lg:w-1/3 !order-1 my-5 lg:!my-0 text-[#482627] ">
          <h5 className="text-center lg:!text-left font-bold text-xl mt-3 mb-3">
            Regina Albinelor
          </h5>
          <p className=" text-lg leading-tight text-center lg:!text-left">
            Excelența nu este un act singular, ci un obicei. Suntem ceea ce
            facem în mod repetat.
          </p>
          <br></br>
          <p className="-black text-lg leading-tight text-center lg:!text-left mb-5">
            Alege "Regina"!
          </p>
        </div>
        <div className="w-full lg:w-1/3 flex justify-center items-center order-first lg:!order-2">
          <img src={logo} className="my-5 lg:!my-0"></img>
        </div>
        <div className="w-full lg:w-1/3 flex flex-col items-center !order-3">
          <div className="flex flex-col">
            <h5 className="text-black font-bold text-xl mt-3 mb-3 lg:!text-left">
              Contact
            </h5>
            <ul className="text-lg text-[#482627]">
              <li className="mb-1">
                <a href="tel:0733-623-202">
                  Telefon : <span className="underline">0733 623 202</span>
                </a>
              </li>
              <li className="mb-1">
                <a href="mailto: office@matcicarnica.ro">
                  Email : <span className="underline">office@matcicarnica.ro</span>
                </a>
              </li>
              <li className="mb-1 flex flex-row items-center lg:!justify-start">
                <span>
                  <FaFacebookF />
                </span>
                <span className="ml-1">
                  <a
                    href="https://www.facebook.com/profile.php?id=100066805262498&ref=py_c&paipv=0&eav=AfbK4mUhaIitDYW1Bs7xAyIAMKu1x_QYPhR2vdqZgGTs9lMN-27X6lwMIxH0rOm9UIQ"
                    target="_blank"
                    className="underline"
                  >
                    Facebook
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-black flex flex-row text-lg mt-3 justify-center lg:!justify-start">
        <span>© Regina albinelor, toate drepturile rezervate</span>
      </div>
    </footer>
  );
}
